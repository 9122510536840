import React from "react";

import {Header} from  "./header"
// import { Features } from "./features";
import { About } from "./about";
import { Services } from "./services";
import { Gallery } from "./gallery";
import { Team } from "./Team";
import { ContactB } from "./contactB";

// import { Testimonials } from "./testimonials";
export const Home = (props) => {
// console.log("HOME PROP", props)
// console.log("HOME", props.data.landingPageData)
    return(
        <>
        <Header data={props.data.Header}/>
        {/* <Header/> */}
            {/* <Header data={landingPageData.Header} /> */}
        {/* <Features data={props.data.Features} /> */}
            <About data={props.data.About} />
            <Services data={props.data.Services} />
            <Gallery data={props.data.Gallery} />
            {/* <Testimonials data={landingPageData.Testimonials} /> */}
            <Team data={props.data.Team} />
            <ContactB data={props.data.Contact} />
        </>

    )
}