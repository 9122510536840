import { useState } from "react";

import { useEffect } from 'react';
// import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  FirstName: "",
  LastName: "",
  Company: "",
  Address: "",
  City: "",
  State: "",
  Zip: "",
  Phone: "",
  Email: "",
  Subject: "",
  Message: "",
  CompanyCode: "246-YAT4NS-UVHKBA-1DCVDX",
  RedirectUrl: "https://google.com",
  action: "https://das.arborgold.net/ag/webleads/addlead"
};



export const ContactB = (props) => {
  const [{ FirstName, LastName, Company,
           Address, City, State,
          Zip, Phone, Email, Subject, Message, CompanyCode}, setState] = useState(initialState);
        

  const [data, setData] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((prevState) => {
      // console.log(prevState)
      return(
        { ...prevState, [name]: value })
      });

  };
  

  const clearState = () => setState({ ...initialState });
  
  
  const handleSubmit  = async (e) => {
    e.preventDefault();
    
    // {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }
    // Object assign creates an Object null error

    // const formData = Object.assign({}, { FirstName, LastName, Company,
    //   Address, City, State, Zip, Phone, Email, Subject, Message, CompanyCode });

    // bypassing error with JSON.Stringify
    const raw = JSON.stringify({ FirstName, LastName, Company, Address, City, State, Zip, Phone, Email, Subject, Message, CompanyCode });




// const raw = JSON.stringify({
//   "LastName": "D2oe",
//   "CompanyCode": "246-YAT4NS-UVHKBA-1DCVDX"
// });


      const requestOptions = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: raw
      }


    try {
      const response = await fetch("https://das.arborgold.net/ag/webleads/addlead", requestOptions);

      const result = await response.json();
      setData(result)

    } catch (error) {
      console.error('Error:', error);
    }
  };



           

  //   // {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }
    
  //   emailjs
  //     .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_PUBLIC_KEY")
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //         clearState();
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  // };

// Use CAPTCHA in form
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    document.head.appendChild(script);
    let grecaptcha

    const webLeadForm=document.getElementById("webleadform");
    webLeadForm.addEventListener("submit",(e) => {
      e.preventDefault() 
      ""!==grecaptcha.getResponse()?webLeadForm.submit():alert("Please check the box indicating that you are not a robot.")
    });

    return () => {
      // Cleanup function to remove the script when the component unmounts
      document.head.removeChild(script);
    };
  }, []); 

  return (
    <div>
      
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>

{/* Proper Example */}

              {/* <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div> */}
    {/* Proper Example End */}

              {/* Custom Form */}

              {/* <form action={formAction} ref={formRef}> */}
              <form onSubmit={handleSubmit}>
                 
                <div id="webleadform" className="form-row">
			            <div className="form-group col-md-6">
                    <input type="text" className="form-control" 
                            id="firstName" name="FirstName"
                            maxLength="50"
                            required 
                            placeholder="First Name"
                            onChange={handleChange}/>
                  </div>
                </div>


                <div className="form-row">
                  <div className="form-group col-md-6">
                    <input type="text" className="form-control" 
                           id="lastName" name="LastName"
                           maxLength="50"
                           required
                           placeholder="Last Name"
                           onChange={handleChange}
                          />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <input type="text" className="form-control" 
                           id="Company" 
                           name="Company" 
                           maxLength="150"
                           placeholder="Company Name"
                           onChange={handleChange}
                           />
                  </div>

                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <input type="text" className="form-control"
                           id="address" name="Address" 
                           maxLength="150" required
                           placeholder="Address"
                           onChange={handleChange}
                           />
                  </div>
                </div>


                <div className="form-row" >
                  <div className="form-group col-md-2">
                    <input type="text" className="form-control" 
                           id="city" name="City" 
                           maxLength="50" 
                           required
                           placeholder="City"
                           onChange={handleChange}/>
                  </div>
                  <div className="form-group col-md-2"> 
                    <input type="text" className="form-control" 
                           id="state" name="State" 
                           maxLength="50"
                           required
                           placeholder="State"
                           onChange={handleChange}/>
                  </div>
                  <div className="form-group col-md-2">
                    <input type="text" className="form-control" 
                           id="zip" name="Zip" maxLength="10" 
                           required
                           placeholder="Zip"
                           onChange={handleChange}/>
                  </div>
                </div>


                <div className="form-row">
                  <div className="form-group col-md-6">
                    <input type="text" className="form-control" 
                           id="phone" name="Phone" maxLength="75" 
                           placeholder="Phone"
                           required
                           onChange={handleChange}
                           />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-12">
                    <input type="email" className="form-control" 
                           id="email" name="Email"
                           aria-describedby="email" 
                           placeholder="Email"
                           maxLength="125" required
                           onChange={handleChange}/>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-12">
                    <input type="text" className="form-control" 
                           id="Subject" name="Subject" maxLength="125" 
                           required
                           placeholder="Subject"
                           onChange={handleChange}/>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <textarea type="text" className="form-control" id="Message" name="Message" 
                              rows="3" maxLength="500"
                              placeholder="Message..."
                              onChange={handleChange}></textarea>
                  </div>
                </div>
                
                <div className="form-row">
                      <div className="form-group col-md-6">
                        {/* <!-- This field must be included in your form --> */}
                        <div className="g-recaptcha" data-sitekey="6Lc-pXIaAAAAABJzS3CHGKdi-owovzuuWhzRQa7T" data-size="compact"></div>
                      </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-12">
                    <button type="submit">Send</button>
                  </div>
                </div>

              </form>




            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          {/* <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2023 Issaaf Kattan React Land Page Template. Design by{" "}
            <a href="http://www.templatewire.com" rel="nofollow">
              TemplateWire
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
