import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
// import { Header } from "./components/header";

import { Home } from "./components/home";
import { Hire } from "./components/hire";


import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
// import {Seth} from "./components/seth"

import "./App.css";
// import "../public/css/sethcss.css"

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  
  console.log("APP", landingPageData)

  return (
    <div>
      <BrowserRouter>
          <Navigation />
          
        <Routes>
          <Route path="/" element={<Home data={landingPageData}  />} />
          {/* <Route path="/hire" element={<Hire data={landingPageData}  />} /> */}

          {/* <Contact data={landingPageData.Contact} /> */}
          {/* <Seth/> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
